import withRoot from "../utils/withRoot";
import React from "react";
import { graphql, Link } from "gatsby";
import Seo from "../components/Seo";
// import Card from "../components/Card";
import Page from "../components/Page";
// import HomeFeatures from "../components/HomeFeatures";
// import Button from "@material-ui/core/Button";
// import Carousel from "../components/Carousel";
// import Avatar from "@material-ui/core/Avatar";
// import { Gift } from "mdi-material-ui";
import withStyles from "@material-ui/styles/withStyles";
// import irukaGIF from '../gifs/iruka-ani12.gif';
import Grid from "@material-ui/core/Grid";
// import BackgroundImage from 'gatsby-background-image';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import background5 from '../images/5.png';

const flag = typeof window !== 'undefined';

// let auth = null;
let windowInstance;
let documentInstance;
let jQuery;
if (flag) {
    jQuery = require('jquery');
    windowInstance = window;
    documentInstance = document;
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#ffffff'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 650,
            md: 1333,
            lg: 1920,
            xl: 2000,
        },
      }
});

const styles = theme => ({
    divider: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
    },
    root: {
        fontWeight: "bold",
        // backgroundColor: '#000'
    },
});

// const authIP = async(state) => {
//   var res = await fetch('/api/authIP', {
//     method: 'POST'
//   }).then((response) => {
//     // console.log(response)
//     return response.text();
//   });

//   // console.log(res)
//   if (res === 'Y') {
//     // console.log(state.state.disabled)
//     state.setState({disabled: false});
//   }
//   // console.log(state.state.disabled)
// }

class Recruit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      seo: false,
      // scrollTopFlag: false,
      windowHeight: 0,
      siteHeight: 0,
      divTop: {},
      divHeight: {}
    }

    var url = this.props.location.href
    if (typeof(url) === 'string') {
      if (url.indexOf('staging') !== -1) {
        // console.log(this.state.disabled)
        this.state.disabled = true
        // console.log(this.state.disabled)
      } else {
        this.state.seo = true
      }
      // console.log(this.state.seo)
    }
    // console.log(this.props.data.testFixed)
    // authIP(this)
  }

  async componentDidMount() {
    windowInstance.addEventListener('scroll', this.handleScroll);
    if (this.state.disabled) {
      var res = await fetch('/api/authIP', {
        method: 'POST'
      }).then((response) => {
        // console.log(response)
        return response.text();
      });
      // console.log(res)
      if (res === 'Y') {
        // console.log(state.state.disabled)
        this.setState({disabled: false});
      }
      // console.log(state.state.disabled)
    }
    // console.log(jQuery('div.gatsby-image-wrapper'))
    jQuery('div.gatsby-image-wrapper').css('height', '');//.removeAttr('style');
    jQuery('div.gatsby-image-wrapper').css('width', '');
    jQuery('div.gatsby-image-wrapper').css('position', '');

    var insertDiv = {}
    var insertHeight = {}
    jQuery('div.animation-init').each(function() {
    insertDiv[jQuery(this).attr('id')] = jQuery(this).offset().top;
    insertHeight[jQuery(this).attr('id')] = jQuery(this).height();
    // console.log(jQuery(this).attr('id'));
    // console.log(jQuery(this).offset().top);
    });


    this.state.divTop = insertDiv
    this.state.divHeight = insertHeight
    // console.log(Object.keys(this.state.divTop))
    // console.log(this.state.divHeight['animation-2-right-title']);
    this.handleScroll();
  }

  renderSeo() {
    return (
      <Seo title="Recruit">
        <meta
            name="description"
            content="AristoBrain株式会社の人材採用、新卒採用・中途採用広く募集しています"
        />
        <meta
            name="keywords"
            content="生命科学AI,生物科学AI,医療AI,行動予測AI,分野融合,企業変革,新規事業,人材採用"
        />
      </Seo>
    )
  }

  handleScroll = () => {
    // if (this.state.scrollTopFlag) {

    var insertDic = {};
    var insertDiv = {};
    var insertHeight = {};
    var predivTop = Object.keys(this.state.divTop);
    // console.log(predivTop);
    if (this.state.siteHeight !== documentInstance.body.clientHeight) {
      insertDic['siteHeight'] = documentInstance.body.clientHeight;
      insertDic['windowHeight'] = windowInstance.innerHeight;
      jQuery('div.animation-init').each(function() {
        if (predivTop.includes(jQuery(this).attr('id'))) {
          insertDiv[jQuery(this).attr('id')] = jQuery(this).offset().top;
          insertHeight[jQuery(this).attr('id')] = jQuery(this).height();
          // console.log(jQuery(this).attr('id'));
          // console.log(jQuery(this).offset().top);
        }
      });
    }

    // var ua = navigator.userAgent;
    // console.log(ua);
    var top = windowInstance.scrollY;
    // // Edge, Safariの場合 
    // if(ua.indexOf('Edge') !== -1 || (windowInstance.chrome || 'WebkitAppearance' in documentInstance.documentElement.style)) {
    //   top = jQuery('body').scrollTop();
    // // Chrome, IE, Firefoxの場合
    // } else {
    //   top = jQuery('html').scrollTop();
    // }
    // console.log(top);
    // console.log(windowInstance.innerHeight)
    // console.log(insertDic);
    if (Object.keys(insertDic).length > 0) {
      if (Object.keys(insertDiv).length > 0) {
        insertDic['divTop'] = insertDiv
        insertDic['divHeight'] = insertHeight
      }
      this.setState(insertDic)
      // console.log(this.state.divTop)
      // console.log(this.state)
    }

    // console.log(top + this.state.windowHeight);
    // console.log(this.state.divTop['animation-2-right-title']);
    // console.log(top);
    // console.log(this.state.divTop['animation-2-right-title'] + this.state.divHeight['animation-2-right-title']);

    var dic = this.state;
    predivTop.forEach(function(key) {
      var number = Number(key.split('-')[1]);
      // console.log(number)
      // var direction, elem;
      // [direction, elem] = key.split('-').slice(2);
      // console.log(direction)
      // console.log(elem)
      // console.log(top + dic.windowHeight);
      // console.log(dic.divTop[key]);
      // console.log(top);
      // console.log(dic.divTop[key] + dic.divHeight[key]);
      if ((top + dic.windowHeight >= dic.divTop[key]) && (top <= dic.divTop[key] + dic.divHeight[key])) {
        if (number === 1) {
          jQuery('div#' + key).addClass('fade-in-news-image');
          jQuery('div#' + key + ' > div > div > h1').addClass('fade-in-news-title');
          jQuery('div#' + key + ' > div > div > p').addClass('fade-in-about-text');
          jQuery('div#' + key + ' > div > div.under-bar-text').addClass('fade-in-news-button');
        } else {
          jQuery('div#' + key).addClass('fade-in-recruit-text');
        }
        // console.log(top + dic.windowHeight);
        // console.log(dic.divTop[key]);
        // console.log(top);
        // console.log(dic.divTop[key] + dic.divHeight[key]);
        delete dic.divTop[key];
        delete dic.divHeight[key];
      }
    });

    this.setState(dic);

    // insertDic['divTop'] = insertDiv
    // this.setState(insertDic)
    // console.log(this.state.siteHeight)
    // console.log(this.state.windowHeight)
    // console.log(this.state.divTop)
    // console.log(windowInstance.scrollY)



    // } else {
    //   this.setState({scrollTopFlag: true});
    // }
  }

  render() {
    // const products = props.data.allMarkdownRemark.edges;
    const {disabled} = this.state;
    // console.log(disabled)
    // if (this.props.location.href.indexOf('staging') != -1) {
    //   disabled = true
    // }

    if (disabled) {
      // console.log(this.state.disabled)
      return (
        <h1>You can't enter in this pages.</h1>
      );
    } else {
      return (
        <Page url={this.props.location.pathname}>
          <ThemeProvider theme={theme}>
            {this.state.seo && this.renderSeo()}
            <Grid
              container
              direction='row'
              justify="center"
              spacing={0}
              alignItems='stretch'
              justifyContent="center"
            >
              <Grid xs={12} id='recruit-1' className='img-plate animation-init' style={{backgroundImage: `url(${background5})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                <div style={{margin: 0, padding: 0}}>
                  <div className='under-bar-title'>
                    <h1>Recruit</h1>
                    <p>
                      求人
                    </p>
                  </div>
                  <div className='under-bar-text'>
                    <p className='thread-text'>社員とインターン生を募集しています</p>
                    <p className='tbody-text' style={{marginTop: '1%', marginBottom: '15%'}}>
                        弊社では以下の５つのが必須条件です。学歴・経歴・知識は問いません。<br />
                        1,「将来、何か大きなことを成し遂げたい」という、高い志を持っている。<br />
                        2,膨大な勉強量をこなす時間を確保できる。<br />
                        3,モチベーションを常に高く維持できる。<br />
                        4,趣味と仕事が一致している。<br />
                        5,体調管理をしっかり出来る。
                    </p>
                  </div>
                </div>
                {/* <HomeFeatures /> */}
              </Grid>
              <Grid sm={6} xs={12} id='recruit-2' className='animation-init left-article'>
                <p className='thread-text' style={{marginBottom: 0}}>個人完結型異分野融合</p>
                <p className='tbody-text' style={{marginTop: '1%', marginBottom: '5%'}}>
                    弊社はイノベーションを重視しており、その土壌として、個人内にいくつもの専門分野をもつことを義務化しています。<br />
                    他の人と何か異なるものを持つことで新しいプロダクトが生まれますが、弊社では、それを「個人内部での複数分野の高度な組み合わせ」に設定しています。一つの分野をマスターするのには1万時間かかると言われます。<br />
                    人生を100年として、人の一生は、100×365×24=876000時間あり、人生の半分の時間を寝て過ごしたとしても、438000時間あるわけです。だいたい、44万時間はあるので、44分野くらいの専門分野は理論上持てるわけです。<br />
                    レオナルド・ダ・ヴィンチは、絵画、彫刻、演出、建築、解剖、軍事、土木、治水、など様々な専門分野を身に着け、彼の死後500年を経ても評価される作品を数多く残しました。最終的なアウトプットは何でもよいのです。<br />
                    スカイツリー,火星基地,絵画,アルゴリズム,治療薬,科学的発見.etc..素晴らしい作品を作るために、まず、素晴らしい人を育てます
                </p>
                <p className='thread-text' style={{margin: 0}}>顧客第一主義</p>
                <p className='tbody-text' style={{marginTop: '1%', marginBottom: '5%'}}>
                    各社員の育成も重要ですが、企業として存在する以上、顧客が求めるものを、顧客が求める、タイミング、品質、場所、で提供することを続けるからこそ、会社が存続し続けることができます。<br />
                    顧客が、便利・使いやすい・楽しい・飽きない、と言ってくれるサービスを提供し続けます。
                </p>
                <p className='thread-text' style={{margin: 0}}>淡々とした日々の努力</p>
                <p className='tbody-text' style={{marginTop: '1%', marginBottom: '5%'}}>
                    締切り前に徹夜して一気にやるようなことは、非効率ですし、長く続きません。<br />
                    毎日無理なく淡々と努力することが重要です。
                </p>
              </Grid>
              <Grid sm={6} xs={12} id='recruit-3' className='animation-init right-article'>
                <p className='thread-text' style={{marginBottom: 0}}>高度な自然体</p>
                <p className='tbody-text' style={{marginTop: '1%', marginBottom: '5%'}}>
                    無理なことをしても結局人は自然体に戻っていきます。その自然体をいかに高度に設計するかが重要です。<br />
                    英語の読み書き話す聞くを徹底的に訓練しておけば、日々の情報収集で臆することなく英語の記事を軽く読み流すことができるでしょう。<br />
                    AWSやGCPなどのクラウド環境の使い方を徹底的に勉強しておけば、いざシステムを作るときに、自然とクラウド環境でシステムを構築できるでしょう。<br />
                    自分と普段話す周りの5人に、特段優秀な人を配置しておけば、特に意識することなく、自分の水準の低さに気づき、高い目標を設定し続けることが出来るでしょう。
                </p>
                <p className='thread-text' style={{margin: 0}}>心を磨き高める</p>
                <p className='tbody-text' style={{marginTop: '1%', marginBottom: '5%'}}>
                    各個人の将来の成功は、心の完成度をいかに高く保ち続けることが出来るかにかかっています。<br />
                    顧客が、便利・使いやすい・楽しい・飽きない、と言ってくれるサービスを提供し続けます。<br />
                    締切り前に徹夜して一気にやるようなことは、非効率ですし、長く続きません。<br />
                    人からどれほど誹謗中傷されようが自分が正しいと信じる道を進むことが出来る強い心を持たなければいけません。孤独に耐える力を持たなければいけません。<br />
                    歴史上、大きな偉業を成し遂げた人たちの多くは、世間からの強い批判にさらされています。数多く挑戦すれば、それだけ失敗の数も多く、身内からは見放され、世間からはバカにされます。<br />
                    本当に新しい誰もやったことに挑戦すれば、長い間失敗を重ねるでしょう。しかし、そういう失敗は、歓迎しましょう。失敗や試練が心を鍛え磨くのです。<br />
                    また、何か上手くいったからといって天狗になってはいけません。以下のようなことわざがあります。「勝って兜の緒を締めよ」、「千丈の堤も螻蟻(ろうぎ)の穴を以て潰(つい)ゆ」。<br />
                    人は上手くいっているときは、人の話を聞かなかったり、自分の力を過信して努力を怠ったりします。<br />
                    一度何かで成功してしまうと、周りから褒めたたえられ、特別扱いされると、素直で努力家な心でいるのが難しくなります。<br />
                    歴史上様々な人が、成功の後、心が変化し、没落していきました。毎日寝る前には、一日を反省し、心を整えるようにしましょう。<br />
                    自信でも謙遜でもなく、道端に生える草のような、私欲のない無の心が望ましいです。しかし、野心・高い志はしっかり持ってください。
                </p>
              </Grid>
              <Grid sm={6} xs={12} id='recruit-4' className='animation-init left-article'>
                <p className='sharp-under-bar'>必要条件</p>
                <p className='tbody-text' style={{marginTop: '1%', marginBottom: '5%'}}>
                    ・少なくとも半年間、技術の習得に励む情熱と時間のある方。目安としては国立大学受験の勉強量くらい。<br />
                    ・データサイエンティストの素養を身につけたい方。<br />
                    ・将来の国の産業を背負うような大きな仕事をしたい方。<br />
                    ・​弊社の方針に共鳴していただける方。
                </p>
                <p className='thread-text' style={{margin: 0}}>（業種）</p>
                <p className='thread-text' style={{margin: 0}}>正社員(エンジニア兼営業兼マーケター)</p>
                <p className='tbody-text' style={{marginTop: '1%', marginBottom: '5%'}}>
                    ・月収25～50万円(能力により異なる。賞与有。最初の6-12カ月は有期雇用契約。)
                </p>
                <p className='thread-text' style={{margin: 0}}>インターン生</p>
                <p className='tbody-text' style={{marginTop: '1%', marginBottom: '5%'}}>
                    ・時給1200円～2000円(能力により異なる)
                </p>
              </Grid>
              <Grid sm={6} xs={12} id='recruit-5' className='animation-init right-article'>
                <p className='sharp-under-bar'>FAQ</p>
                <p className='thread-text' style={{margin: 0}}>報酬はどれくらいになりますか？</p>
                <p className='tbody-text' style={{marginTop: '1%', marginBottom: '5%'}}>
                    社員の場合は月収25～50万円(能力により異なる。賞与有。)<br />
                    インターン生はも能力次第で時給1200円～2000円となります。
                </p>
                <p className='thread-text' style={{margin: 0}}>例えば機械学習に関して、まったく知識がない場合、どのような内容を学びますか？</p>
                <p className='tbody-text' style={{marginTop: '1%', marginBottom: '5%'}}>
                    1,Python基礎 (書籍：実践力を身につける Pythonの教科書)<br />
                    2,PandasとNumpy基礎 (書籍：Pythonによるデータ分析入門 第2版)<br />
                    3,NeuralNetwork基礎 (書籍：ゼロから作るDeep Learning)<br />
                    4,DeepLearning基礎 (オンライン講座：stanford cs231n)<br />
                    5,DeepLearning応用1 (書籍：PythonとKerasによるディープラーニング)<br />
                    6,DeepLearning応用2 (書籍：現場で使える! TensorFlow開発入門 Kerasによる深層学習モデル構築手法) 
                </p>
              </Grid>
            </Grid>
          </ThemeProvider>
        </Page>
      );
    }
  }
};

export const query = graphql`
  query {
    allFile(filter: { extension: { eq: "jpg" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/products/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            image {
              publicURL
            }
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
  }
`;

export default withRoot(withStyles(styles)(Recruit));